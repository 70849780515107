import { TypeHero } from "@contentful/types";
import { formatCurrency, notEmpty } from "@yardzen/js-util";
import { H1, Link, P } from "@yardzen/ui";
import Image from "next/image";
import { ComponentProps } from "react";
import { ImageCarousel } from "../ImageCarousel";
import { AddToCartForm } from "./AddToCartForm";
import { ProductEmailForm } from "./ProductEmailForm";

export function Hero({
  hero,
  slug,
}: {
  hero: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">;
  slug: string;
}) {
  const { images, title } = hero.fields;
  return (
    <section
      aria-label={title}
      className="flex flex-col md:grid md:grid-cols-12 md:gap-10 md:py-10"
    >
      {images && (
        <ImageCarousel
          className="-m-4 mb-0 grid-cols-12 md:col-span-8 md:m-0"
          images={imagesToCarouselImages(images)}
        />
      )}
      <Content hero={hero} slug={slug} />
    </section>
  );
}

function Content({
  hero,
  slug,
}: {
  hero: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">;
  slug: string;
}) {
  const { title, reviews, subtitle, cta, selectOptions, price, description } =
    hero.fields;
  const packageOptions = selectOptions?.filter(notEmpty) || [];
  const checkoutDisplay = cta ? true : false;

  const FLOW_NAME = slug ? slug : "Project Inquiry";
  const EMAIL_FORM_NAME = cta?.fields.formName ?? "Product Email Form";
  const eMailSuccess =
    cta?.fields.successMessage ?? "We have received your e-mail.";

  return (
    <div className="col-span-12 mx-auto flex max-w-screen-sm flex-col gap-4 p-2.5 md:col-span-4">
      <H1 className="text-4xl">{title}</H1>
      {subtitle && (
        <P className="font-small text-sm italic leading-tight tracking-tight">
          {subtitle}
        </P>
      )}

      {reviews && (
        <div className="space-x-2">
          <P className="text-[#F7E011]">{reviews.fields.stars}</P>

          <P className="text-sm font-medium leading-tight tracking-tight">
            <Link href={reviews.fields.reviewUrl}>
              {reviews.fields.reviewCount} Reviews
            </Link>{" "}
            • {reviews.fields.purchases}
          </P>
        </div>
      )}
      <div className="h-0 border"></div>
      {description && <P>{description}</P>}
      {price && (
        <P className="font-display text-4xl font-bold leading-[44px]">
          {checkoutDisplay ? null : formatCurrency(price)}
        </P>
      )}
      {checkoutDisplay ? (
        <div>
          <P className="italic">{cta ? cta.fields.description : null}</P>

          <ProductEmailForm
            tracking={{
              flow_name: FLOW_NAME,
              form_name: EMAIL_FORM_NAME,
            }}
            success={eMailSuccess}
          />
        </div>
      ) : (
        <div>
          <div className="flex flex-col gap-2.5">
            <P className="italic">
              Buy now, pay over time with{" "}
              <Image
                src="/images/pdp/affirm.png"
                className="mb-2 inline"
                alt="affirm"
                width={44}
                height={18}
              />
            </P>

            <AddToCartForm
              packageOptions={packageOptions}
              subFlowName={FLOW_NAME}
            />

            <Link
              className="text-center"
              href="https://calendly.com/yardzen-team/consult-call"
              target="_blank"
            >
              Not sure? Schedule a free call
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

type CarouselImage = ComponentProps<typeof ImageCarousel>["images"][0];

function imagesToCarouselImages(
  images: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">["fields"]["images"],
): CarouselImage[] {
  return (images || [])
    .filter(notEmpty)
    .reduce<CarouselImage[]>((acc, image) => {
      const asset = image?.fields.image?.fields.file;
      if (
        asset?.url &&
        image?.fields.description &&
        asset?.details?.image?.width &&
        asset?.details?.image?.height
      ) {
        return [
          ...acc,
          {
            src: `https:${asset.url}`,
            alt: image.fields.description,
            width: asset.details.image.width,
            height: asset.details.image.height,
          },
        ];
      }
      return acc;
    }, []);
}
