import { TypeHeroSkeleton } from "@contentful/types";
import { customImageLoader } from "@yardzen/next-client-util";
import { Card, CardContent, CardHeader, H1, H5, P } from "@yardzen/ui";
import { Entry } from "contentful";
import Image from "next/image";

export function AboutSection({
  section,
}: {
  section: Entry<TypeHeroSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
}) {
  const backgroundImage =
    section?.fields?.images?.[0]?.fields?.image?.fields?.file?.url || "";

  const heading = section?.fields?.title;

  return (
    <div className="relative h-[960px]">
      <div className="absolute z-40 flex h-full w-full flex-col bg-black/40 px-6 py-8 text-white sm:p-9">
        <div>
          <H1 className="border-none p-0">{heading}</H1>
          <P className="text-xl leading-6 sm:w-2/5">
            {section.fields.description}
          </P>
        </div>
        <div className="flex flex-1 flex-col justify-end">
          <div className="flex flex-col justify-end gap-2 sm:flex-row sm:gap-6">
            {section.fields.CTAs &&
              section.fields.CTAs.map((cta) => (
                <Card className="border-none bg-white/20 backdrop-blur-[10px] sm:w-1/2 md:w-[382px]">
                  <CardHeader className="text-white">
                    <H5>{cta?.fields.title}</H5>
                  </CardHeader>
                  <CardContent className="text-lg text-white">
                    {cta?.fields.description}
                  </CardContent>
                </Card>
              ))}
          </div>
        </div>
      </div>
      <Image
        loader={customImageLoader}
        src={backgroundImage}
        alt="About section background image"
        fill
        priority={true}
        className="max-h-[960px] object-cover"
        loading="eager"
        sizes="100vw"
      />
    </div>
  );
}
