import { TypeProductCollection, TypeProductOffering } from "@contentful/types";
import { formatCurrency, notEmpty } from "@yardzen/js-util";
import Image from "next/image";
import Link from "next/link";

export function OtherOffers({
  otherOffers,
  omittedProduct,
}: {
  otherOffers: TypeProductCollection<"WITHOUT_UNRESOLVABLE_LINKS">;
  omittedProduct: TypeProductOffering<"WITHOUT_UNRESOLVABLE_LINKS">;
}) {
  const products = otherOffers.fields.products
    ?.filter(notEmpty)
    .filter((product) => product.sys.id !== omittedProduct.sys.id);
  if (!products) return null;
  return (
    <div className="flex flex-col gap-6">
      <h3 className="font-display text-3xl">Other Packages</h3>
      <div className="flex gap-4">
        {products.map((product) => (
          <ProductCard key={product.sys.id} product={product} />
        ))}
      </div>
    </div>
  );
}

function ProductCard({
  product,
}: {
  product: TypeProductOffering<"WITHOUT_UNRESOLVABLE_LINKS">;
}) {
  const image = product.fields.hero?.fields.images?.[0];
  if (!image) return;
  const imageFile = image.fields.image?.fields.file;
  if (!imageFile) return;
  const price = product.fields.hero?.fields.price;
  if (!price) return;
  return (
    <Link
      key={product.fields.slug}
      href={`/products/${product.fields.slug}`}
      className="bg-buck-brush w-80 gap-4 rounded-md p-4  transition-all duration-300"
    >
      <h4 className="font-display text-3xl">{product.fields.title}</h4>
      <div className="font-display text-xl font-bold">
        Starting at {formatCurrency(price)}
      </div>
      <div className="relative h-40 w-full">
        <Image
          src={`https:${imageFile.url}`}
          alt={image.fields.description}
          fill
          className="content-center rounded-md"
        />
      </div>
    </Link>
  );
}
