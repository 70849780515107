"use client";

import { TypeHero } from "@contentful/types";
import { notEmpty } from "@yardzen/js-util";
import { customImageLoader } from "@yardzen/next-client-util";
import {
  Card,
  CardContent,
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
} from "@yardzen/ui";
import AutoPlay from "embla-carousel-autoplay";
import Image from "next/image";
export function HeroCarousel({
  hero,
}: {
  hero: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">;
}) {
  const heroImages =
    hero.fields.images
      ?.map((image) => {
        const url = image?.fields.image?.fields.file?.url;
        return url ? `https:${url}` : undefined;
      })
      .filter(notEmpty) || [];

  return (
    <Carousel
      plugins={[
        AutoPlay({
          playOnInit: true,
          stopOnInteraction: true,
          delay: 4000,
        }),
      ]}
      opts={{
        align: "start",
      }}
      className="w-full md:max-h-[768px]"
    >
      <CarouselContent className="m-0 p-0">
        {heroImages.map((image, index) => (
          <CarouselItem
            key={`${image}-${index}`}
            className="m-0 max-h-[768px] p-0"
          >
            <Card className="m-0 h-full border-none p-0">
              <CardContent className="m-0 flex h-full flex-col justify-between border-none p-0">
                <div className="relative aspect-square md:aspect-[16/9]">
                  <Image
                    loader={customImageLoader}
                    src={image}
                    alt={`Hero image ${index + 1}`}
                    fill
                    priority={index === 0}
                    className="max-h-[768px] min-h-[350px] object-cover"
                    loading={index === 0 ? "eager" : "lazy"}
                    sizes="100vw"
                  />
                </div>
              </CardContent>
            </Card>
          </CarouselItem>
        ))}
      </CarouselContent>
      {heroImages.length > 1 && (
        <CarouselDots
          className="absolute bottom-4 hidden flex-row justify-center gap-1 rounded-2xl p-6 py-2 md:flex"
          dotClassName="bg-brand-tertiary-50 h-3 w-3 rounded-full opacity-50"
          selectedDotClassName="bg-brand-tertiary-50 h-3 w-3 rounded-full opacity-100"
          count={heroImages.length}
        />
      )}
    </Carousel>
  );
}
