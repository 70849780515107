import { TypeChoiceBoxesSkeleton } from "@contentful/types";
import { notEmpty } from "@yardzen/js-util";
import { Entry } from "contentful";
import { ChoiceBoxOption } from "./ChoiceBoxOption";

export function ChoiceBoxes({
  entry,
}: {
  entry: Entry<TypeChoiceBoxesSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-lg">{entry.fields.title}</div>
      <div className="grid grid-cols-3 gap-2">
        {entry.fields.options
          ?.filter(notEmpty)
          .map((option) => (
            <ChoiceBoxOption key={option?.sys.id} entry={option} />
          ))}
      </div>
    </div>
  );
}
