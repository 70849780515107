import { TypeDesignGallery } from "@contentful/types";
import { SegmentFlows } from "@yardzen/next-client-util";
import { Button, cn, H1, Link, P } from "@yardzen/ui";
import { cva } from "class-variance-authority";

export function Heading({
  heading,
  segmentFlow,
  className,
  headingClassName,
  subHeadingClassName,
}: {
  heading: TypeDesignGallery<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: SegmentFlows;
  className?: string;
  headingClassName?: string;
  subHeadingClassName?: string;
}) {
  const title = heading?.fields.heading;
  const subtitle = heading?.fields.subheading;
  const cta = heading?.fields.cta;
  const variant = heading?.fields.variant ?? "full-width";

  const variants = cva("", {
    variants: {
      wrapper: {
        "full-width": "",
        "medium-width": "mx-auto md:max-w-[80%] lg:max-w-[60%] xl:max-w-[50%]",
      },
      heading: {
        "full-width": "pt-10 text-4xl md:pt-12 md:text-6xl lg:text-6xl",
        "medium-width": "pt-8 text-3xl md:text-5xl lg:text-5xl",
      },
      subheading: {
        "full-width": "mb-8 mt-5",
        "medium-width": "mt-6 max-md:px-4",
      },
    },
  });

  return (
    <div
      className={cn(
        "flex flex-col items-center pb-8 text-center",
        variants({ wrapper: variant }),
        className,
      )}
    >
      {title && (
        <H1
          className={cn(
            "text-brand-primary-darker px-4",
            variants({ heading: variant }),
            headingClassName,
          )}
        >
          {title}
        </H1>
      )}
      {subtitle && (
        <P
          className={cn(
            "text-brand-primary-dark/[.75] w-[90%]text-base md:min-w-[37%] md:max-w-[65%] md:text-lg",
            variants({ subheading: variant }),
            subHeadingClassName,
          )}
          noSpacing
        >
          {subtitle}
        </P>
      )}
      {cta && (
        <div className="px-8 pt-4">
          <Button
            className="mx-2 w-full"
            asChild
            tracking={{
              button_content: cta.fields.buttonText,
              flow_name: segmentFlow,
              button_name: "Heading",
            }}
          >
            <Link
              className="m-0 whitespace-nowrap font-medium max-[429px]:py-3 max-[390px]:px-3 max-[365px]:whitespace-normal max-[365px]:px-6 max-[365px]:leading-3 min-[360px]:inline-flex"
              href={cta.fields.url}
            >
              {cta.fields.buttonText}
            </Link>
          </Button>
        </div>
      )}
    </div>
  );
}
