import {
  faCircleDollarToSlot,
  faHandHoldingSeedling,
  faHouse,
  faSunBright,
} from "@fortawesome/pro-light-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { cn } from "@yardzen/next-client-util";
import { HTMLAttributes, forwardRef } from "react";

export function ValuePropositions() {
  return (
    <div className="flex flex-col items-center justify-center gap-4 p-5 text-white md:flex-row">
      <div className="font-display text-center text-4xl leading-[44px] md:max-w-[201px]">
        Why a Yardzen yard?
      </div>
      <ul className="grid gap-4 min-[300px]:grid-cols-1 min-[400px]:grid-cols-2 min-[800px]:grid-cols-4">
        <ListItem>
          <Icon icon={faSunBright} />
          <div className="font-display text-center text-[22px] leading-7">
            Spend more time outside
          </div>
        </ListItem>
        <ListItem>
          <Icon icon={faCircleDollarToSlot} />
          <div className="font-display text-center text-[22px] leading-7">
            Increase your home value
          </div>
        </ListItem>
        <ListItem>
          <Icon icon={faHouse} />
          <div className="font-display text-center text-[22px] leading-7">
            Have pride&nbsp;of ownership
          </div>
        </ListItem>
        <ListItem>
          <Icon icon={faHandHoldingSeedling} />
          <div className="font-display text-center text-[22px] leading-7">
            Make a positive mark
          </div>
        </ListItem>
      </ul>
    </div>
  );
}

function Icon({ className, ...props }: FontAwesomeIconProps) {
  return (
    <FontAwesomeIcon
      className={cn(
        "h-10 w-10 transition-all md:h-12 md:w-12 lg:h-14 lg:w-24",
        className,
      )}
      {...props}
    />
  );
}

const ListItem = forwardRef<HTMLLIElement, HTMLAttributes<HTMLLIElement>>(
  ({ children, className, ...props }, ref) => {
    return (
      <li
        ref={ref}
        className={cn(
          "inline-flex w-40 flex-col items-center justify-start gap-2",
          className,
        )}
        {...props}
      >
        {children}
      </li>
    );
  },
);

ListItem.displayName = "ListItem";
