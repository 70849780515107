import { TypeHero } from "@contentful/types";
import { SegmentFlows } from "@yardzen/next-client-util";
import { HeroShowcase } from "./HeroShowcase";

/**
 * Uses Contentful "variant" dropdown to display either
 * background: HeroCarousel
 * slider: PhotoSliderCarousel
 * showcase: Text top with Image below full width
 * showcase-medium: Text top with Image below
 * split: Alternating Left/Right Image with text opposite
 */
export function HeroVariant({
  hero,
  segmentFlow,
  isDesignProfileQuizEnabled,
}: {
  hero: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: SegmentFlows;
  isDesignProfileQuizEnabled?: boolean;
}) {
  // TODO: Once PhotoSliderCarousel and HeroCarousel are move out of /home to this contentful area we can do
  // return (
  //   hero?.fields.variant === 'background' ? (
  //     <HeroCarousel hero={hero} />
  //   ) : (hero?.fields.variant === 'slider') ? (
  //     <PhotoSliderCarousel
  //         hero={hero}
  //         segmentFlow={segmentFlow}
  //         sectionName="Easiest Path to Yard You Love"
  //     /> */}
  //   ) : (hero?.fields.variant === 'showcase-medium' || hero?.fields.variant === 'showcase-large' || hero?.fields.variant === 'split') && (
  //     <HeroShowcase hero={hero} segmentFlow={segmentFlow} isOddSequence={isOddSequence} />
  //   )
  // );
  return (
    (hero?.fields.variant === "showcase-medium" ||
      hero?.fields.variant === "showcase-large" ||
      hero?.fields.variant === "hero-left-split" ||
      hero?.fields.variant === "hero-right-split") && (
      <HeroShowcase
        hero={hero}
        segmentFlow={segmentFlow}
        isDesignProfileQuizEnabled={isDesignProfileQuizEnabled}
      />
    )
  );
}
