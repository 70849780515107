import { TypeCta } from "@contentful/types";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  SegmentClickTypes,
} from "@yardzen/ui";

export function VideoModal({
  contentfulCTA,
  segmentFlow,
}: {
  contentfulCTA: TypeCta<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="w-full px-4 py-3 md:w-auto"
          variant={contentfulCTA.fields.variant}
          tracking={{
            flow_name: segmentFlow,
            button_name: contentfulCTA.fields.buttonText || "Video Modal",
            click_type: SegmentClickTypes.BUTTON,
            button_content: contentfulCTA.fields.buttonText || "Video Modal",
          }}
        >
          {contentfulCTA.fields.buttonText || "Play video"}
        </Button>
      </DialogTrigger>
      <DialogContent className="w-11/12 max-w-screen-lg rounded-lg border-0 p-0 md:w-4/6">
        <DialogClose className="bg-brand-primary-light absolute -top-10 right-0 z-20 -mt-1 h-10 w-10 rounded-full md:-right-10">
          <FontAwesomeIcon
            icon={faXmark}
            className="mt-0.5 h-4 w-4 text-white"
          />
          <span className="sr-only">Close</span>
        </DialogClose>
        {/* 56.25% is 16:9 aspect ratio */}
        <div className="relative z-10" style={{ paddingBottom: "56.25%" }}>
          <iframe
            width="560"
            height="315"
            className="absolute h-full w-full rounded-lg"
            src={`${contentfulCTA.fields.url}?autoplay=1`}
            title={contentfulCTA.fields.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </DialogContent>
    </Dialog>
  );
}
