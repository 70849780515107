import { TypeBeforeAndAfter } from "@contentful/types";
import { Link, P, SegmentClickTypes, SegmentFlowNames } from "@yardzen/ui";
import { AnchorHTMLAttributes, forwardRef } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

export function BeforeAndAfter({
  beforeAndAfter,
}: {
  beforeAndAfter: TypeBeforeAndAfter<"WITHOUT_UNRESOLVABLE_LINKS">;
}) {
  const beforeImagePath = beforeAndAfter.fields.before?.fields.file?.url;
  const afterImagePath = beforeAndAfter.fields.after?.fields.file?.url;
  const title = beforeAndAfter.fields.title;
  const description = beforeAndAfter.fields.description;
  const eyebrow = beforeAndAfter.fields.eyebrow;

  if (!beforeImagePath || !afterImagePath || !title || !description) {
    return null;
  }
  return (
    <div className="mx-auto flex max-w-screen-lg flex-col items-start justify-center p-5 md:px-10 md:py-12">
      <div className="flex grid-cols-[18rem_3fr] flex-col items-start gap-4 md:grid md:gap-8">
        <div className="flex flex-col gap-4">
          <h3 className="font-display text-3xl">
            Inspiring
            <br />
            Before & After
          </h3>
          <div className="md:bg-buck-brush flex flex-col gap-4 rounded-lg md:p-4">
            {eyebrow && (
              <div className="text-xs font-bold capitalize text-[#154632]">
                {eyebrow}
              </div>
            )}
            <h4 className="font-display text-2xl">{title}</h4>
            <P className="max-w-screen-sm">{description}</P>
          </div>
          <SeeAllButton
            flowName={SegmentFlowNames.PACKAGES}
            className="hidden md:block"
          />
        </div>
        {/* TODO: optimize with Next images https://yardzen.atlassian.net/browse/GROW-415  */}
        <ReactCompareSlider
          className="mx-auto"
          itemOne={
            <ReactCompareSliderImage src={beforeImagePath} alt="Image one" />
          }
          itemTwo={
            <ReactCompareSliderImage src={afterImagePath} alt="Image two" />
          }
        />
        <SeeAllButton
          flowName={SegmentFlowNames.PACKAGES}
          className="place-self-center md:hidden"
        />
      </div>
    </div>
  );
}

const SeeAllButton = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement> & { flowName: string }
>(({ flowName, ...props }, ref) => {
  return (
    <Link
      tracking={{
        flow_name: flowName,
        button_name: "Before & After Button",
        click_type: SegmentClickTypes.NAV,
      }}
      ref={ref}
      variant="button"
      href="https://yardzen.com/yard-tour"
      {...props}
    >
      See All Before & Afters
    </Link>
  );
});
SeeAllButton.displayName = "SeeAllButton";
