import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  isTypeDesignGallery,
  isTypeListItemCollection,
  isTypePackageDetailItem,
  isTypeVideoTeaser,
  TypeMultiModuleContainer,
} from "@contentful/types";
import { SegmentFlows } from "@yardzen/next-client-util";
import { Button, cn, H1, Link } from "@yardzen/ui";
import { cva } from "class-variance-authority";
import { Entry, EntrySkeletonType } from "contentful";
import { Heading } from "./Heading";
import { ListItemCollection } from "./ListItemCollection";
import { PackageDetailItem } from "./PackageDetailItem";
import { VideoTeaser } from "./VideoTeaser";

export function MultiModuleContainer({
  multiModuleContainer,
  segmentFlow,
  isDesignProfileQuizEnabled,
  className,
}: {
  multiModuleContainer: TypeMultiModuleContainer<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: SegmentFlows;
  isDesignProfileQuizEnabled?: boolean;
  className?: string;
}) {
  // The right or left module could take up 1/3 or 2/3 area
  const variantResolved = multiModuleContainer?.fields.variant ?? "50-50";
  const moduleVariants = cva("", {
    variants: {
      colSpanTotal: {
        "33-67": "lg:grid-cols-7",
        "50-50": "lg:grid-cols-2",
        "67-33": "lg:grid-cols-7",
      },
      colSpanLeft: {
        "33-67": "lg:col-span-3",
        "50-50": "lg:col-span-1",
        "67-33": "lg:col-span-4",
      },
      colSpanRight: {
        "33-67": "lg:col-span-4",
        "50-50": "lg:col-span-1",
        "67-33": "lg:col-span-3",
      },
      module1: {
        listItemCollection: "",
        default: "",
      },
      module2: {
        listItemCollection: "",
        default: "",
      },
    },
    compoundVariants: [
      // If module2 is listItemCollection mobile 1 col
      {
        module1: "default",
        module2: "listItemCollection",
        class: "max-md:grid-cols-1",
      },
    ],
  });

  const renderModule = (
    module: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS", string>,
    dottedBorder?: string,
    isModuleTwo?: boolean,
  ) => {
    if (!module) return null;
    const colSpanClass = isModuleTwo
      ? moduleVariants({ colSpanRight: variantResolved })
      : moduleVariants({ colSpanLeft: variantResolved });
    const modClass =
      "flex flex-1 flex-col justify-center gap-2 md:flex-row lg:flex-col";
    return (
      <>
        {isTypeDesignGallery(module) && (
          <div
            className={cn(
              "border-system-grayscale-20 rounded-lg border border-dashed",
              modClass,
              colSpanClass,
            )}
          >
            <Heading
              heading={module}
              className="lg:items-start lg:text-left"
              headingClassName="text-3xl md:text-3xl lg:text-3xl px-8 pt-8"
              subHeadingClassName="text-brand-primary-dark md:text-base px-8 w-full md:max-w-full mt-4"
              segmentFlow={segmentFlow}
            />
          </div>
        )}
        {isTypeListItemCollection(module) && (
          <div className={cn(dottedBorder, colSpanClass)}>
            <ListItemCollection
              listItemCollection={module}
              disableDynamicColumns
              listItemHeadingClassName="text-base md:text-lg pb-1"
              listItemSubHeadingClassName="text-xs md:text-sm"
              listItemsClassName={moduleVariants({
                module1: module1Type,
                module2: module2Type,
              })}
            />
          </div>
        )}
        {isTypeVideoTeaser(module) && (
          <div className={cn(modClass, colSpanClass)}>
            <VideoTeaser videoTeaser={module} segmentFlow={segmentFlow} />
          </div>
        )}
        {isTypePackageDetailItem(module) && (
          <div className={cn(modClass, colSpanClass)}>
            <div className="bg-brand-moss-greens-10 w-full max-w-7xl rounded-lg bg-opacity-50 p-4 max-md:max-w-full md:px-5 md:py-4">
              <PackageDetailItem
                packageDetailItem={module}
                segmentFlow={segmentFlow}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const module1Type =
    multiModuleContainer?.fields.module1 &&
    isTypeListItemCollection(multiModuleContainer?.fields.module1)
      ? "listItemCollection"
      : "default";
  const module2Type =
    multiModuleContainer?.fields.module2 &&
    isTypeListItemCollection(multiModuleContainer?.fields.module2)
      ? "listItemCollection"
      : "default";
  const moduleTypeVariants = cva("", {
    variants: {
      module1: {
        listItemCollection: "",
        default: "grid grid-cols-1 justify-center gap-4",
      },
      module2: {
        listItemCollection: "",
        default: "grid grid-cols-1 justify-center gap-4",
      },
      border1: {
        listItemCollection: "",
        default: "",
      },
      border2: {
        listItemCollection: "",
        default: "",
      },
    },
    compoundVariants: [
      // If both modules are listItemCollection use a different wrapper class
      {
        module1: "listItemCollection",
        module2: "listItemCollection",
        class: "flex flex-col justify-center gap-8 md:flex-row",
      },
      // If module2 is listItemCollection use the dotted border
      {
        border1: "default",
        border2: "listItemCollection",
        class: "border-system-grayscale-20 rounded-lg border border-dashed p-5",
      },
    ],
  });

  const module1 =
    multiModuleContainer?.fields.module1 &&
    renderModule(
      multiModuleContainer?.fields.module1,
      moduleTypeVariants({ border1: module1Type, border2: module2Type }),
    );
  const module2 =
    multiModuleContainer?.fields.module2 &&
    renderModule(
      multiModuleContainer?.fields.module2,
      moduleTypeVariants({ border1: module1Type, border2: module2Type }),
    );

  const heading = multiModuleContainer?.fields.heading;
  const subheading = multiModuleContainer?.fields.subheading;

  const ctaUrl = isDesignProfileQuizEnabled
    ? multiModuleContainer?.fields.moduleCta?.fields.challengerURL
    : multiModuleContainer?.fields.moduleCta?.fields.url;
  const ctaButtonText =
    multiModuleContainer?.fields.moduleCta?.fields.buttonText;

  return (
    <div
      className={cn(
        "mt-5 flex flex-col items-center justify-center px-1 text-center max-md:mt-4 md:px-8",
        className,
      )}
    >
      {heading && (
        <H1 className="text-brand-primary-darker px-4 pt-12 text-4xl">
          {heading}
        </H1>
      )}
      {subheading && (
        <div className="text-brand-primary-dark/[.75] mt-4 w-[90%] pb-8 text-base md:min-w-[37%] md:max-w-[65%] md:text-lg">
          {documentToReactComponents(subheading)}
        </div>
      )}
      <div className="w-full max-w-7xl rounded-lg bg-opacity-0 max-md:max-w-full">
        <div
          className={cn(
            moduleTypeVariants({ module1: module1Type, module2: module2Type }),
            moduleVariants({ colSpanTotal: variantResolved }),
          )}
        >
          {module1}
          {module2}
        </div>
      </div>
      {ctaButtonText && (
        <div className="flex items-end p-6 px-0 pb-2 pt-0 md:pt-2">
          <Button
            className="mx-2 w-full"
            asChild
            tracking={{
              button_name: "Multi Module Container CTA",
              button_content: ctaButtonText,
              flow_name: segmentFlow,
            }}
          >
            <Link
              className="hidden whitespace-nowrap font-medium max-[429px]:py-3 max-[390px]:px-3 max-[365px]:whitespace-normal max-[365px]:px-6 max-[365px]:leading-3 min-[360px]:inline-flex"
              href={ctaUrl}
            >
              {ctaButtonText}
            </Link>
          </Button>
        </div>
      )}
    </div>
  );
}
